.button {
		color: #1F363D;
		text-transform: uppercase;
		text-decoration: none;
		background: transparent;
		padding: .5em 2em;
		min-height: rem-calc(60);
		border-radius: rem-calc(30);
		border: rem-calc(4) solid #1F363D;;
		display: inline-block;
		transition: all 0.4s ease 0s;
		text-transform: uppercase;
		font-weight: 600;
		line-height: 1.2;
	&:hover {
		color: #fff;
		background-color: adjust-color(#1F363D, $lightness: 20%);
		border-color: #1F363D;
	}
}