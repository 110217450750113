.rainbow-grid {
	&__container {
		width: 100%;
		height: 100%;
		display: grid;
		grid-gap: 2px;
		grid-template-columns: repeat(15, 1fr);
		grid-template-rows: repeat(15, 1fr);
		div {
			// border: 1px solid black;
			opacity: 0.7;
			&:hover {
				opacity: 1.0;
				transform: scale(1.2);
				border: 1px solid black;
			}
			&:nth-of-type(8n+1) {
				background: red;
			}
			&:nth-of-type(8n+2) {
				background: orange;      
			}
			&:nth-of-type(8n+3) {
				background: yellow;
			}
			&:nth-of-type(8n+4) {
				background: lime;     
			}
			&:nth-of-type(8n+5) {
				background: forestgreen;     
			}
			&:nth-of-type(8n+6) {
				background: dodgerblue;
			}
			&:nth-of-type(8n+7) {
				background: blue
			}
			&:nth-of-type(8n) {
				background: indigo;
			}
		}
	}
}