.goodmajor {
	&__bg {
		height: 100%;
		background-image: url('../img/goodmajor-background.jpg');
		background-color: #ffe630;
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		transition: filter .5s;
	}
	&__logo {
		position: absolute;
		top: 0;
		left: 50%;
		margin-top: rem-calc(80);
		margin-left: rem-calc(-158);
		transition: transform .5s;
	}
}

.portfolio__container {
	&:hover {
		.goodmajor__bg {
			filter: brightness(80%) blur(1px);
		}
		.goodmajor__logo {
			z-index: 3;
			transform: scale(1.15);
		}
	}
}