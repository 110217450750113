
@mixin color-palette {
	&:nth-child(5n)   {	background-color: #1F363D; }
	&:nth-child(5n+1) { background-color: #40798C; }
	&:nth-child(5n+2) { background-color: #70A9A1; }
	&:nth-child(5n+3) {	background-color:	#9EC1A3; }
	&:nth-child(5n+4) { background-color:	#CFE0C3; }
}

body {
	font-family: sans-serif;
	background-color: #1b1b1b;
}

P {
	margin-block-start: .3em;
	margin-block-end: .3em;
}

.mosaic {
	&__container {
		max-width: rem-calc(1200);
		display: grid;
		grid-gap: 10px;
		grid-auto-flow: dense;
		margin: auto;
		padding: 10px;
		@include breakpoint(large) {
			margin-top: rem-calc(30);
		}
		grid-template-columns: repeat(3, 1fr);
		@include breakpoint(medium) {
			grid-template-columns: repeat(4, 1fr);
		}
		@include breakpoint(large) {
			grid-template-columns: repeat(5, 1fr);
		}
		@include breakpoint(xlarge) {
			grid-template-columns: repeat(6, 1fr);
		}

	}
	&__tile {
		position: relative;
		padding: 1rem;
		color: #333;
		border-radius: 5px;
		@include color-palette;
		// By default, (empty) tiles are 1x1
		// Medium are 2x2 on small/medium screens, 1x1 from large up
		// Large are 3x3 on small/medium screens, 2x2 from large up
		// XL are 3x3 throughout
		&--md {
			grid-column-end: span 2;
			grid-row-end: span 2;
			@include breakpoint(medium) {
				grid-column-end: span 1;
				grid-row-end: span 1;
			}
		}
		&--lg {
			grid-column-end: span 3;
			grid-row-end: span 3;
			@include breakpoint(large) {
				grid-column-end: span 2;
				grid-row-end: span 2;
			}
		}
		&--xl {
			grid-column-end: span 3;
			grid-row-end: span 3;
		}
		// Force aspect ratio to square
		&::before {
			content: "";
			padding-bottom: 100%;
			display: inline-block;
			vertical-align: top;
		}
	}
}

.portfolio {
	&__container {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		overflow: hidden;
		border-radius: 5px;
		left: 0;
		&:hover {
			.portfolio__content {
				transform: scale(1.1);
			}
			.portfolio__description {
				bottom: 0;
			}
		}
	}
	&__content {
		position: relative;
		transition: transform .5s;
		height: 100%;
	}
	&__description {
		position: absolute;
		transition: bottom .5s;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		text-align: center;
		padding: 1em 2em;
		width: 100%;
		background-color: adjust-color(#CFE0C3, $lightness: 10%); 
		&--image {
			padding: 1em 1em 0 1em;
			justify-content: flex-end;
			background-color: transparent;
		}
	}
} 


// Height of the description block varies based on class AND the size of the containing tile
.mosaic__tile {
	.portfolio__description {
		height: 100%;
		bottom: -100%;
		&--image {
			transition: all .15s ease-in-out;
			height: 100%;
			bottom: -45%;
			&:hover {
			}
		}
	}
	// Medium TILE
	&--md {
		.portfolio__description {
			height: 50%;
			bottom: -50%;
		}
		.portfolio__description--lg {
			height: 100%;
			bottom: -100%;
		}
		.portfolio__description--xl {
			height: 100%;
			bottom: -100%;
		}
	}
	// Large TILE
	&--lg {
		.portfolio__description--lg {
			height: 67%;
			bottom: -67%;
		}
		.portfolio__description--xl {
			height: 100%;
			bottom: -100%;
		}
	}
	// XLarge TILE
	&--xl {
		.portfolio__description--lg {
			height: 50%;
			bottom: -50%;
		}
		.portfolio__description--xl {
			height: 67%;
			bottom: -67%;
		}
	}
}


